import { throttle } from 'lodash-es';

export const Header = (function () {
	'use strict';

	let header,
		mobileStocks,
		mobileMenuTrigger,
		subMenuTriggers,
		mobileMenuBackButton,
		titleStack,
		submenuStack,
		mobileMenuTitle,
		megaMenuTriggers,
		lastScrollY,
		$steelPrice;

	function init() {
		if (document.querySelector('.site-header') === null) return;

		header = document.querySelector('.site-header');
		mobileStocks = document.querySelector('.site-header__stocks-bar--mobile');
		mobileMenuTrigger = document.getElementById('mobileMenuTrigger');
		mobileMenuBackButton = document.getElementById('mobileMenuBack');
		subMenuTriggers = document.querySelectorAll('.mobile-nav .menu-item-has-children > a, .mobile-nav .menu-item-has-children > span');
		mobileMenuTitle = document.querySelector('.site-header__main .mobile-menu-title');
		megaMenuTriggers = document.getElementById('menu-main-menu').querySelectorAll('.top-item.menu-item-has-children');
		lastScrollY = window.scrollY;
		titleStack = [];
		submenuStack = [];
		$steelPrice = $('.steel-price-us');

		window.addEventListener(
			'scroll',
			throttle(() => {
				window.scrollY < lastScrollY ? header.classList.add('site-header--appear') : header.classList.remove('site-header--appear');
				lastScrollY = window.scrollY;
			}, 100)
		);
		mobile_menu_logic();

		if ( typeof premiumStockMarketWidgets !== 'undefined' ) {
			$.ajax({
				method: 'POST',
				url: '/wp-admin/admin-ajax.php',
				cache: false,
				data: {
					action: 'smwGetMarketData',
					assets: ['^STEEL'],
					api: 'yf',
					nonce: premiumStockMarketWidgets.ajaxNonce,
					type: 'card'
				},
				dataType: 'json',
				success: function(data) {
					let roundPct = data.data[0].change_pct * 100;
					roundPct = Math.round(roundPct * 100) / 100;
					var tickerClass = ( roundPct < 0 ) ? 'current--down' : 'current--up';
					$steelPrice.addClass(tickerClass).text(roundPct + '%');

				}
			});
		} else {
			$steelPrice.parents('.site-header__stocks-bar').hide();
		}


	}

	function mobile_menu_logic() {
		if (!mobileMenuTrigger) return;

		mobileMenuTrigger.addEventListener('click', () => {
			document.body.classList.toggle('mobile-nav-open');
			mobileMenuTrigger.setAttribute('aria-expanded', document.body.classList.contains('mobile-nav-open'));
		});

		subMenuTriggers.forEach((t) => {
			t.addEventListener('click', (ev) => {
				ev.preventDefault();

				let targetSubMenu = t.parentNode.querySelector('.sub-menu, .mobile-nav__sub-level');

				mobileMenuBackButton.classList.add('active');
				mobileStocks.classList.remove('active');
				mobileMenuTitle.textContent = t.textContent;
				targetSubMenu.classList.add('active');
				titleStack.push(t.textContent);
				submenuStack.push(targetSubMenu);
			});

			t.addEventListener('touchstart', (ev) => {
				ev.preventDefault();

				let targetSubMenu = t.parentNode.querySelector('.sub-menu, .mobile-nav__sub-level');

				mobileMenuBackButton.classList.add('active');
				mobileStocks.classList.remove('active');
				mobileMenuTitle.textContent = t.textContent;
				targetSubMenu.classList.add('active');
				titleStack.push(t.textContent);
				submenuStack.push(targetSubMenu);
			});
		});

		mobileMenuBackButton.addEventListener('click', () => {
			if (submenuStack.length === 0) return;

			submenuStack[submenuStack.length - 1].classList.remove('active');
			submenuStack.pop();
			titleStack.pop();
			mobileMenuTitle.textContent = titleStack[titleStack.length - 1];

			if (submenuStack.length === 0) {
				mobileMenuBackButton.classList.remove('active');
				mobileStocks.classList.add('active');
			}
		});

		megaMenuTriggers.forEach((item) => {
			let closeButton = item.querySelector('.mega-menu__close-button');

			if (null !== closeButton)
				closeButton.addEventListener('click', (ev) => {
					ev.stopPropagation();
					item.classList.remove('active');
				});

			item.addEventListener('mouseenter', (ev) => {
				if (item.classList.contains('active')) return;

				megaMenuTriggers.forEach((t) => t.classList.remove('active'));

				ev.preventDefault();
				item.classList.toggle('active');
			});

			item.addEventListener('mouseleave', (ev) => {
				// if (item.classList.contains('active')) return;

				megaMenuTriggers.forEach((t) => t.classList.remove('active'));

				ev.preventDefault();
				item.classList.remove('active');
			});

		});
	}

	return {
		init: init,
	};
})();

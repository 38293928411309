export const StructuredList = (function () {
    'use strict';

	function init() {
		const bodyEl = document.querySelector('body');

        // Kill all this logic in the admin panel
		if (bodyEl.classList.contains('wp-admin')) return;

        $('.structured-list').each(function (i) {
            var $this = $(this);
            var $btn = $this.find('.structured-list__btn');
            var $panel = $this.find('.structured-list__panel');

            $btn.on('click', function(e){
                e.preventDefault();
                $btn.toggleClass('active');
                $panel.toggleClass('active');
                $panel.stop().slideDown();
                if(!$panel.hasClass('active')){
                    $panel.stop().slideUp();
                }
            })
		});
    }

	return {
		init: init,
	};
})();

import scrollMonitor from 'scrollmonitor';
import anime from 'animejs';
import { ANIMATIONS } from '../data/Animations';

export const Animator = (function () {
	'use strict';

	let animators;

	function init() {
		const bodyEl = document.querySelector('body');
		animators = document.querySelectorAll('[data-animator]');

		if (animators.length === 0 || bodyEl.classList.contains('wp-admin')) return;

		animators.forEach((el) => {
			if (!el.hasAttribute('data-animation-type') || !ANIMATIONS.hasOwnProperty(el.getAttribute('data-animation-type'))) {
				el.classList.add('in-viewport');
				return;
			}

			let animationName = el.getAttribute('data-animation-type');
			let watcher = scrollMonitor.create(el, -200);
			let animationOptions = {
				targets: el,
				autoplay: false,
				begin: (anim) => el.classList.add('in-viewport'),
			};

			for (const key in ANIMATIONS[animationName]) {
				if (ANIMATIONS.hasOwnProperty(animationName)) animationOptions[key] = ANIMATIONS[animationName][key];
			}

			if (el.hasAttribute('data-delay')) animationOptions.delay = +el.getAttribute('data-delay');
			if (el.hasAttribute('data-duration')) animationOptions.duration = +el.getAttribute('data-duration');

			let animation = anime(animationOptions);

			watcher.enterViewport(() => {
				if (!el.classList.contains('in-viewport')) animation.play();
			});
		});
	}

	return {
		init: init,
	};
})();

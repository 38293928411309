import ScrollMagic from 'scrollmagic';
import 'jquery-scrambler/jquery.scrambler';

/**
 * Something in here messes with the scrolling events, probably the scramble animations
 */
export const Animations = (function () {
	'use strict';

	var wordDuration;

	function init() {
		// Kill all this logic in the admin panel
		const bodyEl = document.querySelector('body');

		if (bodyEl.classList.contains('wp-admin')) return;

		var $map = $('.map');
		var $getFadeIns = $('.animate--fade-in'); // Fade In
		var $getRibbonsLeft = $('.animate--ribbon-left'); // Ribbon Left
		var $getRibbonsRight = $('.animate--ribbon-right'); // Ribbon Left

		// Triggers
		if ($(window).width() > 767) {
			var controller = new ScrollMagic.Controller();
			addTriggers($getFadeIns, 'fade-in', controller);
			addTriggers($getRibbonsLeft, 'ribbons-left', controller);
			addTriggers($getRibbonsRight, 'ribbons-right', controller);
			addTriggers($map, 'map--animated', controller);
		}

		//Decoder
		var $getScambleAnimations = $('.animate--scramble');
		scrambleAnimations($getScambleAnimations);

		//Scrambler Text
		var $getScramblers = $('[data-scramble]');
		wordDuration = 300;

		window.addEventListener('load', (event) => {
			scramblerText($getScramblers);
		});
	}

	function scrambleAnimations(elements) {
		if (elements.length === 0) return null;
		elements.each(function () {
			var $this = $(this);
			if (!$this.hasClass('animate--scramble')) {
				$this.addClass('animate--scramble');
			}
			$this.prepend('<span aria-hidden="true"></span>');
			$this.find('span').scrambler({
				effect: 'charbychar',
				final_text: $this.text(),
				keep_whitespaces: true,
				speed: 50,
				reveal: 100, // number of miliseconds to reveal one character
			});
		});
	}

	function scrambleLoop($elem) {

		var elText = $elem.attr('data-text') !== undefined ? $elem.attr('data-text') : $elem.text();

		$elem.show().siblings().removeAttr('style').hide();

		$elem.parent().css({
			lineHeight: $elem.parent().css('line-height'),
			width: $elem.width(),
			height: $elem.outerHeight(),
		});

		$elem.scrambler({
			effect: 'charbychar',
			final_text: elText,
			keep_whitespaces: true,
			speed: 50,
			reveal: 100, // number of miliseconds to reveal one character
		});

		var $nextElem = $elem.next().length ? $elem.next() : $elem.parent().children().eq(0);

		setTimeout(function () {
			scrambleLoop($nextElem);
		}, 5000);
	}

	function scramblerText(elements) {
		if (elements.length === 0) return null;
		elements.each(function () {
			var $this = $(this);
			var $words = $this.find('span');
			var totalWords = $words.length;
			scrambleLoop($words.eq(0));
		});
	}

	function addTriggers(elements, triggerName, controller) {
		elements.each(function (i) {
			var $this = $(this);
			var elementTrigger = 'trigger-' + triggerName + '-' + i;
			$this.attr('id', 'animate--reveal-' + i);
			$this.before('<div id="' + elementTrigger + '" class="animate--trigger"></div>');
			addScene(elementTrigger, $this.attr('id'), controller);
		});
	}

	function addScene(trigger, reveal, controller) {
		var revealElement = reveal;
		var triggerElement = trigger;

		revealElement.charAt(0) === '#' ? revealElement : (revealElement = '#' + reveal);
		triggerElement.charAt(0) === '#' ? triggerElement : (triggerElement = '#' + triggerElement);

		new ScrollMagic.Scene({
			triggerElement: triggerElement,
			triggerHook: 0.7, // show, when scrolled 10% into view
			reverse: true,
		})
			.setClassToggle(revealElement, 'animated') // add class to reveal
			.addTo(controller);
	}

	return {
		init: init,
	};
})();

export const Modals = (function () {
	'use strict';

	function init() {
		MicroModal.init({
			onShow: onShowCallback,
			onClose: onCloseCallback,
		});

		if (window.location.hash) hashChangeHandler();

		window.addEventListener('hashchange', hashChangeHandler);
	}

	function hashChangeHandler(ev) {
		let targetModalId = window.location.hash.replace('#', '');
		let targetModal = document.getElementById(targetModalId);

		if (targetModal !== null && targetModal.classList.contains('modal'))
			MicroModal.show(targetModalId, {
				onShow: onShowCallback,
				onClose: onCloseCallback,
			});
	}

	function onShowCallback(modal) {
		history.replaceState({ currentModal: modal.id }, null, `#${modal.id}`);
		document.body.classList.add('modal--is-open');
		console.log(history.state);
	}

	function onCloseCallback(modal) {
		history.replaceState({ currentModal: null }, null, ' ');
		document.body.classList.remove('modal--is-open');
		console.log(history.state, modal);
	}

	return {
		init: init,
	};
})();

export const ProductMenu = (function () {
	'use strict';

	var $scrollingFooter;

	function init() {
		const bodyEl = document.querySelector('body');

		// Kill all this logic in the admin panel
		if (bodyEl.classList.contains('wp-admin')) return;

		var lastScrollTop = 0;
		$scrollingFooter = $('.scrolling-footer');

		$('body, .two-columns > div').scroll(function (event) {
			console.log('test');
			var scrollFromTop = $(this).scrollTop();
			if (scrollFromTop > lastScrollTop) {
				// downscroll code
				$scrollingFooter.addClass('scrolling-footer--appear');
				// $(document.body).addClass('scrolled');
			} else {
				// upscroll code
				$scrollingFooter.removeClass('scrolling-footer--appear');
			}
			lastScrollTop = scrollFromTop;
		});
	}

	return {
		init: init,
	};
})();

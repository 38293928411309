import { throttle, debounce } from 'lodash-es';

export const HomeProgressBar = (function () {
	'use strict';

	let siteHeader,
		bar,
		barContainer,
		verticalLabels,
		slices,
		blocks,
		lastBlockID,
		totalScrollHeight,
		slider,
		lastActiveSliceIndex = -1,
		hasScrolled = false,
		darkClassList = ['pxblock--banner', 'layout--dark'];

	function init() {
		bar = document.getElementById('homepageProgressBar');
		if (bar == null) return null;
		barContainer = bar.querySelector('.progress-bar-container');
		slider = bar.querySelector('.progress');
		verticalLabels = document.querySelectorAll('.pxblock[id^="acf/"] > .pxblock__vertical-label');
		siteHeader = document.querySelector('.site-header');
		blocks = document.querySelectorAll('.pxblock[id^="acf/"]');
		totalScrollHeight = document.body.getBoundingClientRect().height - window.innerHeight;

		if (null === bar || verticalLabels.length === 0) {
			if (null !== bar) bar.parentNode.removeChild(bar);
			return;
		}

		build_progress_bar();

		slices.forEach((slice, idx) => {
			slice.addEventListener('click', () => {
				window.scroll({
					top: verticalLabels[idx].parentNode.offsetTop - siteHeader.getBoundingClientRect().height,
					behavior: 'smooth',
				});
			});
		});

		window.addEventListener(
			'resize',
			debounce(() => {
				totalScrollHeight = document.body.getBoundingClientRect().height - window.innerHeight;
				animate_bar();
			}, 100)
		);

		window.addEventListener(
			'scroll',
			throttle(() => {
				animate_bar();

				verticalLabels.forEach((verticalLabel, idx) => {
					if (
						verticalLabel.getBoundingClientRect().y > 0 &&
						verticalLabel.getBoundingClientRect().y < 200 + siteHeader.getBoundingClientRect().height &&
						lastActiveSliceIndex !== idx
					) {
						slices.forEach((slice) => slice.classList.remove('active'));
						slices[idx].classList.add('active');
						lastActiveSliceIndex = idx;

						if (!hasScrolled) {
							bar.classList.add('scrolled');
							hasScrolled = true;
						}
					}
				});

				blocks.forEach((block) => {
					let a = bar.getBoundingClientRect();
					let b = block.getBoundingClientRect();

					if (b.top <= a.top && a.top + a.height <= b.top + b.height && block.id !== lastBlockID) {
						Array.from(block.classList).filter((x) => darkClassList.includes(x)).length > 0
							? bar.classList.add('light')
							: bar.classList.remove('light');
						lastBlockID = block.id;
					}
				});
			}, 100)
		);
	}

	function animate_bar() {
		let scrollPercentage = Math.ceil((window.scrollY / totalScrollHeight) * 100);
		let sliderTranslateValue = scrollPercentage - 100;

		if (sliderTranslateValue > 0) sliderTranslateValue = 0;

		slider.style.transform = 'translateY(' + sliderTranslateValue + '%)';
	}

	function build_progress_bar() {
		for (let i = 0; i < verticalLabels.length; i++) {
			let slice = document.createElement('div');
			let number = document.createElement('span');

			slice.classList.add('slice');
			number.classList.add('slice__number');
			number.innerText = i < 9 ? '0' + (i + 1) : i + 1;

			slice.appendChild(number);
			barContainer.appendChild(slice);
		}

		slices = bar.querySelectorAll('.slice');
	}

	return {
		init: init,
	};
})();

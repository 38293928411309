import { throttle } from 'lodash-es';
import Cookies from 'js-cookie';
import Select from 'tether-select';
import scrollMonitor from 'scrollmonitor';
import anime from 'animejs';
import AOS from 'aos';

export const Helpers = (function () {
	'use strict';

	function init() {
		AOS.init({
			duration: 500,
			easing: 'ease-out-quart',
			once: true,
		});

		cookie_banner();
		body_scroll();
		form_elements();
		vertical_labels();
		external_links();
		animated_timeline();

		block_anchors();
	}

	function block_anchors() {
		var $anchor = $(window.location.hash);
		$(window).on('hashchange', function(){
			$('.menu-item').removeClass('active');
		});
		if ( $anchor.length ) {
			$('html, body').animate({
				scrollTop: $anchor.offset().top
			}, 500);
		}
	}

	function animated_timeline() {
		if (null === document.querySelector('.animated-timeline')) return;

		document.querySelectorAll('.animated-timeline').forEach((timeline) => {
			let watcher = scrollMonitor.create(timeline, -200);
			let animation = anime({
				targets: timeline.querySelectorAll('.animated-timeline__word'),
				autoplay: false,
				opacity: [0, 1],
				translateX: function (el, i) {
					return i % 2 === 0 ? ['-200%', '-100%'] : ['100%', 0];
				},
				begin: (anim) => timeline.classList.add('in-viewport'),
				delay: anime.stagger(250),
				easing: 'easeOutQuart',
			});

			watcher.enterViewport(() => {
				if (!timeline.classList.contains('in-viewport')) animation.play();
			});
		});
	}

	function external_links() {
		document.querySelectorAll('a').forEach((link) => {
			if (link.hostname && link.hostname !== location.hostname) link.setAttribute('target', '_blank');
		});
	}

	function vertical_labels() {
		Array.from(document.querySelectorAll('.pxblock__vertical-label'))
			.filter((el) => {
				return window.getComputedStyle(el, null).display !== 'none';
			})
			.forEach((label, idx) => {
				let numberLabel = document.createElement('strong');
				let textLabel = document.createElement('span');
				textLabel.innerText = label.textContent;

				numberLabel.textContent = idx < 9 ? '0' + (idx + 1) : idx + 1;
				label.innerHTML = '';

				if ($('body').hasClass('home')) {
					label.appendChild(numberLabel);
				}
				label.appendChild(textLabel);
			});
	}

	function form_elements() {
		// Select.init();
	}

	function cookie_banner() {
		let banner = document.querySelector('.cookie-banner');

		if (!banner) return;

		banner.querySelectorAll('button').forEach((btn) => {
			btn.addEventListener('click', (ev) => {
				ev.preventDefault();

				let acceptance = btn.classList.contains('accept') ? 'yes' : 'no';

				Cookies.set('acceptedCookiePrompt', acceptance);
				window.location.reload();
			});
		});
	}

	function responsive_videos() {
		let responsiveVideos = document.querySelectorAll('.responsive-video');

		if (!responsiveVideos) return;

		responsiveVideos.forEach((vid) => {
			let playButton = vid.querySelector('.play-button');
			let videoEl = vid.querySelector('video');
			let iframe = vid.querySelector('iframe');
			let cover = vid.querySelector('.responsive-video__cover');

			if (!playButton) return;

			if (iframe) {
				let iframeOriginalSrc = iframe.getAttribute('src');

				iframe.setAttribute('src', iframe.getAttribute('src') + '&showinfo=0&controls=0');
			}

			playButton.addEventListener('click', (ev) => {
				vid.classList.add('playing');
			});

			playButton.addEventListener('transitionend', (ev) => {
				playButton.parentNode.removeChild(playButton);

				if (cover) {
					cover.parentNode.removeChild(cover);
				}

				if (videoEl) {
					videoEl.setAttribute('controls', 'controls');
					videoEl.play();
				}

				if (iframe) {
					iframe.setAttribute('src', iframe.getAttribute('src') + '&autoplay=1');
				}
			});
		});
	}

	function body_scroll() {
		if (window.scrollY > 100) document.body.classList.add('scrolled');

		window.addEventListener(
			'scroll',
			throttle(() => {
				window.scrollY > 100 ? document.body.classList.add('scrolled') : document.body.classList.remove('scrolled');
			}, 150)
		);
	}

	function getUrlParams(search) {
		if (search.length <= 1) return {};
		const hashes = search.slice(search.indexOf('?') + 1).split('&');
		const params = {};
		hashes.map((hash) => {
			const [key, val] = hash.split('=');
			params[key] = decodeURIComponent(val);
		});
		return params;
	}

	function accordions() {
		let accordions = Array.from(document.querySelectorAll('[data-accordion]'));

		if (accordions.length === 0) return;

		accordions.forEach((accordion) => {
			let trigger = accordion.querySelector('[data-accordion-trigger]');

			trigger.addEventListener('click', (ev) => {
				accordion.classList.toggle('accordion-open');
			});
		});
	}

	return { init, getUrlParams, external_links };
})();
